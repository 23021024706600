<template>
    <div v-if="page">
        <div v-if="!!page.doc.content" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>
        <div class="card-group" v-for="category in page.academy" :key="category.title">
            <h2 class="py-0 w-100">{{category.title}}</h2>
            <div class="row">
                <div class="col-6 col-lg-4 col-xl-3" v-for="video in category.materials" :key="video.title">
                    <academy-youtube v-if="video.type === 'youtube'" :src="video.link" :title="video.title" />
                    <academy-internal v-if="video.type === 'internal'" :title="video.title" :link="video.link" :img="video.img" />
                    <academy-file v-if="video.type === 'file'" :title="video.title" :link="video.link" />
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mapGetters} from "vuex";
import AcademyYoutube from "@/components/Information/AcademyYoutube";
import gql from "graphql-tag";
import AcademyInternal from "@/components/Information/AcademyInternal";
import AcademyFile from "@/components/Information/AcademyFile";
import PageTitle from "@/components/common/PageTitle";

export default {
    name: "Academy",
    components: {
        AcademyFile,
        AcademyInternal,
        AcademyYoutube,
        PageTitle},
    data() {
        return {
            page: null
        }
    },
    computed: {
        ...mapGetters({
            youtube: 'academy/youtube'
        })
    },
    apollo: {
        page: {
            query: gql`query{doc(id:"academy"){content,title},academy{type,title,materials{type,title,link,img}},viewer{id,totals{tokens}},viewerIsAdmin}`,
            update: data => data,
            result({data}) {
                if (data) {
                    this.$store.commit('user/updateAvailable', {
                        tokens: data.viewer.totals.tokens
                    })
                    if (data.viewerIsAdmin) {
                        this.$store.commit('user/setAdmin')
                    }
                }
            }
        }
    }
}
</script>
