<template>
    <div class="card mb-3">
        <div class="card-header card-header-small">
            {{title}}
        </div>
        <div class="d-flex card-body">
            <a :href="fullUrl(link)" class="text-center">
                <img :src="fullUrl(img)" :alt="title" class="card-img internal-video" />
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "AcademyInternal",
    props: [
        "title",
        "link",
        "img"
    ],
    methods: {
        fullUrl(src) {
            return this.baseURL + src;
        }
    }
}
</script>

<style scoped>
.card-header-small {
    min-height: 2rem;
    height: unset;
}

.internal-video {
    height: 150px;
    width: auto;
    max-width: 100%;
}
</style>