<template>
    <div class="card mb-3">
        <div class="card-header card-header-small">
            {{title}}
        </div>
        <div class="d-flex card-body">
            <iframe class="w-100"
                    :src="embedUrl(src)"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
            ></iframe>

        </div>
    </div>
</template>

<script>
export default {
    name: "AcademyYoutube",
    props: [
        "src",
        "title"
    ],
    methods: {
        embedUrl(url) {
            return "https://www.youtube.com/embed/" + url.split("watch?v=")[1];
        }
    }
}
</script>

<style scoped>
.card-header-small {
    min-height: 2rem;
    height: unset;
}
</style>