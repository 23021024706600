<template>
    <div class="card mb-3">
        <div class="card-body">
            <div class="fsize-3">
                {{title}}
                <a :href.sync="fileUrl" class="card-link ml-3" ><font-awesome-icon :icon="['fas', 'file-alt']" /></a>
            </div>

        </div>
    </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'

library.add(faFileAlt)
export default {
    name: "AcademyFile",
    props: [
        "title",
        "link"
    ],
    components: {
        "font-awesome-icon": FontAwesomeIcon,
    },
    computed: {
        fileUrl() {
            return encodeURI(this.link)
        }
    }
}
</script>

<style scoped>
.card-header-small {
    min-height: 2rem;
}
</style>